<div class="font-sans overflow-hidden flex flex-col sm:rounded-lg sm:shadow h-full">
  <div class="bg-white px-5 py-5 border-b border-gray-200 sm:pr-0">
    <div class="-ml-4 -mt-4 flex flex-col sm:flex-row justify-between sm:items-center flex-wrap sm:flex-nowrap">
      <div class="ml-4 mt-4 text-2xl leading-6 font-medium text-gray-900 flex-1">
        Claims
        <div class="text-sm text-gray-400 mt-2">
          @if (filterTitle) {
            @for (filter of filterTitle.split(','); track filter) {
              <span class="inline-flex rounded-full items-center py-0.5 px-2.5 mr-1 text-sm font-medium bg-sky-100 text-sky-700">
                {{filter}}
              </span>
            }
          }
          @if (filterTitle) {
            <span class="inline-flex rounded-full items-center py-0.5 pl-2.5 pr-1 mr-1 text-sm font-medium bg-gray-100 text-gray-700">
              Clear All
              <button type="button" class="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-gray-400 hover:bg-gray-200 hover:text-gray-500 focus:outline-none focus:bg-gray-500 focus:text-white" (click)="deleteDrillDown()">
                <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                  <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
                </svg>
              </button>
            </span>
          }
        </div>
      </div>
      <div class="ml-4 mt-4 flex flex-col sm:flex-row sm:flex-grow-0 gap-2 flex-grow items-center">
        @if (!defaultCustomerId) {
          <sn-customer-search class="w-full md:w-72" [customerID]="dateFilter?.customerId" (customer)="customerSelected($event);"></sn-customer-search>
        }
        <mat-form-field class="flex">
          <mat-label>Search Claims</mat-label>
          <input matInput [(ngModel)]="searchTerm" #search>
          <mat-icon matSuffix class="cursor-pointer" (click)="searchData()">search</mat-icon>
        </mat-form-field>
        <sn-date-filter-selector class="flex flex-1 max-width-250-640" [dateFilter]="dateFilter" (dateChanged)="dateChanged($event)"></sn-date-filter-selector>
        <div class="flex h-[100px]">
          <button mat-icon-button class="mt-2" [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="exportToExcel()">
              <i class="far fa-file-excel mr-2"></i>
              Export to Excel
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>
  <div [class.hidden]="totalRecords <= 0" class="flex flex-grow bg-white overflow-auto">
    <table mat-table [dataSource]="data" matSort [trackBy]="trackClaim" [matSortActive]="savedState.sortColumn" [matSortStart]="savedState.sortDirection">
      @for (column of selectedColumns; track trackColumn($index, column)) {
        <ng-container [matColumnDef]="column.columnName">
          <th mat-header-cell [mat-sort-header]="column.columnName" *matHeaderCellDef>{{column.label}}</th>
          <td mat-cell *matCellDef="let element">
            @switch (column.columnName) {
              @case ('id') {
                @if (isASMUser) {
                  <a class="link" [routerLink]="['/claims', element.id]">{{element.id}}</a>
                }
                @if (!isASMUser) {
                  <span>{{element.id}}</span>
                }
              }
              @case ('statusDisplayText') {
                @if (element.statusDisplayText == 'Pending') {
                  <span class="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">Pending</span>
                }
                @if (element.statusDisplayText == 'Submitted') {
                  <span class="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">Submitted</span>
                }
                @if (element.statusDisplayText == 'Rebuttal') {
                  <span class="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">Rebuttal</span>
                }
                @if (element.statusDisplayText == 'Carrier Declined') {
                  <span class="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">Carrier Declined</span>
                }
                @if (element.statusDisplayText == 'ASM Declined') {
                  <span class="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">ASM Declined</span>
                }
                @if (element.statusDisplayText == 'Closed (Carrier Paid)') {
                  <span class="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">Closed (Carrier Paid)</span>
                }
                @if (element.statusDisplayText == 'Closed (ASM Paid)') {
                  <span class="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">Closed (ASM Paid)</span>
                }
                @if (element.statusDisplayText == 'Closed (Other)') {
                  <span class="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">Closed (Other)</span>
                }
              }
              @case ('shipmentId') {
                <a class="link" [routerLink]="['/shipments', element.shipmentId]">{{element.shipmentId}}</a>
              }
              @default {
                <sn-grid-column [column]="column" [element]="element"></sn-grid-column>
              }
            }
          </td>
        </ng-container>
      }
      <tr mat-header-row *matHeaderRowDef="savedState.columns;sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: savedState.columns;"></tr>
    </table>
  </div>
  <mat-paginator  [class.hidden]="totalRecords <= 0" class="flex justify-self-start bg-gray-50" #matPaginator [pageIndex]="savedState.page" [pageSize]="savedState.pageSize" [pageSizeOptions]="[25, 50, 100, 250]" [length]="totalRecords" [showFirstLastButtons]="true"></mat-paginator>
  <div [class.hidden]="totalRecords == -1 || totalRecords > 0" class="h-full flex items-center justify-center bg-white">
    <sn-material-grid-empty-table
      [tableName]="'Claims'"
      [showAddButton]="false"
      >
    </sn-material-grid-empty-table>
  </div>
</div>
